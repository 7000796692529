import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { XMasonry, XBlock } from "react-xmasonry/dist/index.js";

import laguna from './assets/images/ranch-laguna/laguna_vintage-1946.png';
import tropLVwedding from './assets/images/cof-wedding/trop-lv-wedding/tropicana-lv-wedding.jpg';
import cofWedding from './assets/images/cof-wedding/cof-wedding-sign.jpg';
import influentialLogo from './assets/images/influential/influential-logo-light.png';
import yourlvtherapistCouch from './assets/images/yourlvtherapist/couch.svg';
import yourlvtherapistDots from './assets/images/yourlvtherapist/dots-preloader.gif';
import hrs from './assets/images/hrs/hrs.png';
import APIcardFront from './assets/images/abc/api-card-front.png';
import APIcardBack from './assets/images/abc/api-card-back.png';
import jobFair from './assets/images/abc/job-fair-after.png';
import NBcustomizer from './assets/images/rvw/nb-customizer2.png';
import UNcampaign from './assets/images/influential/UN-campaign.png';
import profilePicUpload from './assets/images/abc/profile-pic-upload.png';
import profilePicUploading from './assets/images/abc/profile-pic-uploading.png';
import invisionLogo from './assets/images/invision/invision-logo.svg';
import allegiant from './assets/images/allegiant/allegiant-air-banner.png';
import sobelWestex from './assets/images/sobel-westex/logo.png';
import mgmResorts from './assets/images/mgm-resorts/mgm-resorts-logo.png';
import clThumb from './assets/images/abc/cl-thumb.svg';

import ABC from "./pages/ABC/ABC";
import Allegiant from "./pages/Allegiant/Allegiant";
import COFWeddings from "./pages/COFWeddings/COFWeddings";
import HRS from "./pages/HRS/HRS";
import Influential from "./pages/Influential/Influential";
import InVision from "./pages/InVision/InVision";
import KaraUrieLott from "./pages/KaraUrieLott/KaraUrieLott";
import MGMResorts from "./pages/MGMResorts/MGMResorts";
import NewBalance from "./pages/NewBalance/NewBalance";
import RanchLaguna from "./pages/RanchLaguna/RanchLaguna";
import SobelWestex from "./pages/SobelWestex/SobelWestex";
import Footer from "./Footer";

class App extends Component {

  render() {
    return (

      <div>
        <div>
          <div id="gradient_panel"></div>
          <Router>
            <XMasonry>
              <XBlock width="2">
                <Link 
                  to="/RanchLaguna#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="img-crop">
                    <img 
                      className="layered"
                      src={laguna}
                      alt="The Ranch at Laguna Beach"
                    />
                    <img 
                      className="fancy-effect"
                      src={laguna}
                      alt="The Ranch at Laguna Beach"
                    />
                  </div>
                </Link>
              </XBlock>
              
              <XBlock>
                <Link 
                  to="/Influential#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="img-crop">
                    <img 
                      className="layered"
                      src={influentialLogo}
                      alt="Influential"
                    />
                    <img 
                      className="fancy-effect"
                      src={influentialLogo}
                      alt="Influential"
                    />
                  </div>
                </Link>
              </XBlock>
              
              <XBlock>
                <Link 
                  to="/HRS#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                <div className="img-crop">
                  <img 
                    className="layered"
                    src={hrs}
                    alt="Hard Right Solutions"
                  />
                  <img 
                    className="fancy-effect"
                    src={hrs}
                    alt="Hard Right Solutions"
                  />
                </div>
                </Link>
              </XBlock>

              <XBlock  width="2">
                <Link 
                  to="/COFWeddings#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="img-crop">
                    <img 
                      className="layered"
                      src={tropLVwedding}
                      alt="Tropicana Las Vegas Weddings"
                    />
                    <img 
                      className="fancy-effect"
                      src={tropLVwedding}
                      alt="Tropicana Las Vegas Weddings"
                    />
                  </div>
                </Link>
              </XBlock>

              <XBlock  width="1">
                <Link 
                  to="/NewBalance#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="img-crop">
                    <img 
                      className="layered"
                      src={NBcustomizer}
                      alt="New Balance Team Sports Custom Apparel"
                    />
                    <img 
                      className="fancy-effect"
                      src={NBcustomizer}
                      alt="New Balance Team Sports Custom Apparel"
                    />
                  </div>
                </Link>
              </XBlock>

              <XBlock width="2">
                <Link 
                  to="/InVision#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="img-crop">
                    <img 
                      className="invision-logo layered"
                      src={invisionLogo}
                      alt="Invision Enterprise"
                    />
                    <img 
                      className="invision-logo fancy-effect"
                      src={invisionLogo}
                      alt="Invision Enterprise"
                    />
                  </div>
                </Link>
              </XBlock>

              {/* <XBlock width="1" data-extra-class="in-color">
                <section id="hero">
                  <span>
                    Experience
                    <hr/>
                  </span>
                  <span>Start where you are. Use what you have. Do what you can.</span>
                  <span>UI   /   UX   /   FRONT-END</span>
                  <button onClick={this.handleAboutMe}>About Me</button>
                </section>
              </XBlock> */}

              <XBlock width="2">
                <Link 
                  to="/Influential#influential-campaigns" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="img-crop">
                    <img 
                      className="layered"
                      src={UNcampaign}
                      alt="United Nations Peace Day Campaign"
                    />
                    <img 
                      className="fancy-effect"
                      src={UNcampaign}
                      alt="United Nations Peace Day Campaign"
                    />
                  </div>
                </Link>
              </XBlock>

              <XBlock>
                <Link 
                  to="/KaraUrieLott#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="img-crop yourlvtherapist-logo layered">
                    <img 
                      className="logo"
                      src={yourlvtherapistCouch}
                      alt="yourlvtherapist.com logo"
                    />
                    <img 
                      className="dots"
                      src={yourlvtherapistDots}
                      alt="yourlvtherapist.com logo"
                    />
                  </div>
                  <div className="img-crop yourlvtherapist-logo fancy-effect">
                    <img 
                      className="logo"
                      src={yourlvtherapistCouch}
                      alt="yourlvtherapist.com logo"
                    />
                    <img 
                      className="dots"
                      src={yourlvtherapistDots}
                      alt="yourlvtherapist.com logo"
                    />
                  </div>
                </Link>
              </XBlock>

              <XBlock height="1">
                <Link 
                  to="/ABC#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="img-crop">
                    <img 
                      className="layered"
                      src={jobFair}
                      alt="ABC Financial - Engineering Job Fair"
                    />
                    <img 
                      className="fancy-effect"
                      src={jobFair}
                      alt="ABC Financial - Engineering Job Fair"
                    />
                  </div>
                </Link>
              </XBlock>

              <XBlock>
                <Link 
                  to="/ABC#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="layered">
                    <div className="card-fade">
                      <img 
                        className="card-upload"
                        src={profilePicUpload}
                        alt="ABC Financial Check-In"
                      />
                      <img 
                        className="card-uploading"
                        src={profilePicUploading}
                        alt="ABC Financial Check-In"
                      />
                    </div>
                  </div>

                  <div className="fancy-effect overlay-blend">
                    <div className="card-fade">
                      <img 
                        className="card-upload"
                        src={profilePicUpload}
                        alt="ABC Financial Check-In"
                      />
                      <img 
                        className="card-uploading"
                        src={profilePicUploading}
                        alt="ABC Financial Check-In"
                      />
                    </div>
                  </div>
                </Link>
              </XBlock>

              <XBlock>
                <Link 
                  to="/SobelWestex#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})} 
                >
                  <div className="img-crop">
                    <img 
                      className="sobel-logo layered"
                      src={sobelWestex}
                      alt="Sobel Westex"
                    />
                    <img 
                      className="sobel-logo fancy-effect"
                      src={sobelWestex}
                      alt="Sobel Westex"
                    />
                  </div>
                </Link>
              </XBlock>

              <XBlock>
                <Link 
                  to="/MGMResorts#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="img-crop">
                    <img 
                      className="mgm-logo layered"
                      src={mgmResorts}
                      alt="MGM Resorts"
                    />
                    <img 
                      className="mgm-logo fancy-effect"
                      src={mgmResorts}
                      alt="MGM Resorts"
                    />
                  </div>
                </Link>
              </XBlock>

              <XBlock>
                <Link 
                  to="/ABC#influential-component-library" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="img-crop">
                    <img 
                      className="layered"
                      src={clThumb}
                      alt="ABC Financial - Style Guide/Component Library"
                    />
                    <img 
                      className="fancy-effect"
                      src={clThumb}
                      alt="ABC Financial - Style Guide/Component Library"
                    />
                  </div>
                </Link>
              </XBlock>
              
              <XBlock  width="1">
                <Link 
                  to="/COFWeddings#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="img-crop">
                    <img 
                      className="layered"
                      src={cofWedding}
                      alt="Chapel of the Flowers Las Vegas Weddings"
                    />
                    <img 
                      className="fancy-effect"
                      src={cofWedding}
                      alt="Chapel of the Flowers Las Vegas Weddings"
                    />
                  </div>
                </Link>
              </XBlock>

              <XBlock width="1">
                <Link 
                  to="/Allegiant#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="img-crop">
                    <img 
                      className="layered"
                      src={allegiant}
                      alt="Allegiant Air"
                    />
                    <img 
                      className="fancy-effect"
                      src={allegiant}
                      alt="Allegiant Air"
                    />
                  </div>
                </Link>
              </XBlock>

              <XBlock>
                <Link 
                  to="/ABC#my-work" 
                  scroll={el => el.scrollIntoView({ behavior: 'smooth'})}
                >
                  <div className="layered">
                    <div className="card-flip">
                      <div className="card-flip-inner">
                        <img 
                          className="card-front"
                          src={APIcardFront}
                          alt="ABC Financial API"
                        />
                        <img 
                          className="card-back"
                          src={APIcardBack}
                          alt="ABC Financial API"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="fancy-effect overlay-blend">
                    <div className="card-flip">
                      <div className="card-flip-inner">
                        <img 
                          className="card-front"
                          src={APIcardFront}
                          alt="ABC Financial API"
                        />
                        <img 
                          className="card-back"
                          src={APIcardBack}
                          alt="ABC Financial API"
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </XBlock>

            </XMasonry>

            <div id="my-work">
              <Route path="/ABC" component={ABC} />
              <Route path="/Allegiant" component={Allegiant} />
              <Route path="/COFWeddings" component={COFWeddings} />
              <Route path="/HRS" component={HRS} />
              <Route path="/Influential" component={Influential} />
              <Route path="/InVision" component={InVision} />
              <Route path="/KaraUrieLott" component={KaraUrieLott} />
              <Route path="/MGMResorts" component={MGMResorts} />
              <Route path="/NewBalance" component={NewBalance} />
              <Route path="/RanchLaguna" component={RanchLaguna} />
              <Route path="/SobelWestex" component={SobelWestex} />
            </div>
          </Router>

          <Footer/>

        </div>
      </div>
    );
  }
}

export default App;