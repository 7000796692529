import React, { Component } from "react";
import Glide from '@glidejs/glide'

import Sidebar from "./../../components/Sidebar";
import Concept from "./../../components/Concept";
import {ChallengeBlock, Challenges} from "./../../components/Challenges";
import {PageHeader, SubHeading} from "./../../components/PageHeader";

import mobileScreenshot1 from "./../../assets/images/rvw/mobile-1.png";
import mobileScreenshot2 from "./../../assets/images/rvw/mobile-2.png";
import brineLogo from "./../../assets/images/rvw/logo-brine.png";
import warLogo from "./../../assets/images/rvw/logo-war.png";

class NewBalance extends Component {
  componentDidMount() {
    new Glide('.glide').mount();
  }

  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
    
    const sliderImgs = importAll(require.context(`./slider`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div>
        <div className="content-new-balance">
          <div className="container content-block">
            
            <PageHeader
              title={"New Balance Team Sports"}
              techDescription={["A phase 1 of the New Balance Team Sports site was already live by the time I joined ", 
                <a href="">Red Van Workshop</a>,
                ". My role was to fix existing UI bugs and build new or expand upon existing components based on Phase 2 comps/specs provided by New Balance’s in-house designers.",
                <div><br/></div>, 
                "RVW uses a proprietary ecommerce platform called Demandware, which is similar to a Visual Studio/.NET environment. Demandware training was conducted in formal certification courses.",
                <strong><br/><br/><strike>Fun</strike> Fact: </strong>,
                "Demandware was eventually bought out by Salesforce."
              ]}
            >
              <SubHeading 
                skill={"Development"}
                link={"http://www.newbalanceteam.com"}
                linkTitle={"newbalanceteam.com"}
              />
            </PageHeader>
            
            <div className="glide">
              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">

                  {sliderImgs.map(function(name, index) {
                    return <li className="glide__slide" key={index}><img src={name[1]} alt="Slider slide"/></li>;
                  })}

                </ul>
              </div>
              <div className="glide__bullets row" data-glide-el="controls[nav]">
                {sliderImgs.map(function(name, index) {
                  return <button className="glide__bullet col-2" key={index} data-glide-dir={`=${index}`}><img src={name[1]} alt="Slider navigation"/></button>;
                })}
              </div>
              <Sidebar 
                className={"right"} 
                // disclaimer={"Disclaimer text here."}
                sectionTitle={"Product Customizer"}
                description={"Customize almost every aspect of your sports team’s apparel and equipment."}
                >
              </Sidebar>
            </div>

            <Concept 
              txt={["A user to the site has the option to make individual purchases or to customize an entire cart of products (like maybe a coach of a city sport team might).",
                <div><br/></div>,
                "With the latter, the user can send the list top the team and do such things as set required vs. optional purchases, limit customizations for the members to login and set their own (such as their size), and set deadlines on when member selections should be completed by.",
                <div><br/></div>,
                "A single user can create an account by a specified role (coach, team captain, etc.) or an entire company or group can create a single account to represent multiple roles."
              ]}
            >
            </Concept>

            <div className="split-container">
              <div className="row">
                <div className="col-4 left">
                  <div className="logo-container">
                    <img src={brineLogo} className="brine-logo" alt="Brine - mobile"/>
                    <img src={warLogo} className="warrior-logo" alt="Warrior - mobile"/>
                  </div>
                  <p>Warrior and Brine are members of the New Balance Family of Brands, and are responsible for all custom hockey equipment.</p>
                </div>
                <div className="col-8 right">
                  <img src={mobileScreenshot2} className="mobile-ss-brine" alt="Brine - mobile"/>
                  <img src={mobileScreenshot1} className="mobile-ss-warrior" alt="Warrior - mobile"/>
                </div>
              </div>
            </div>

            <Challenges 
              sectionTitle={"Challenges"}>
              <ChallengeBlock 
                columnClass={"col-12 col-sm-6"}
                txt={"Pretty heavy learning curve coming from open source technologies."}/>
              <ChallengeBlock 
                columnClass={"col-12 col-sm-6"}
                txt={"Very difficult to leave this company. Every person there is unconditionally friendly and respectful of one another and of clients, even in the face of stress."}/>
            </Challenges>
          </div>
        </div>
      </div>
    );
  }
}

export default NewBalance;
