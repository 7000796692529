import React, { Component } from 'react';

class Concept extends Component {

  render() {

    return (
      <div className="concept-container">
        <hr/>
        <p className="section-title">Concept</p>
        <p className="concept-txt">{this.props.txt}</p>
        <hr/>
      </div>
    );
  }
}

export default Concept;
