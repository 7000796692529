import React, { Component } from "react";
import Glide from '@glidejs/glide'
import { HashLink as Link } from 'react-router-hash-link';

import Concept from "./../../components/Concept";
import {ChallengeBlock, Challenges} from "./../../components/Challenges";
import {PageHeader, SubHeading} from "./../../components/PageHeader";

import concept2_1 from "./../../assets/images/abc/concepts/ogconcepts-2-1.png";
import concept2_2 from "./../../assets/images/abc/concepts/ogconcepts-2-2.png";
import concept2_3 from "./../../assets/images/abc/concepts/ogconcepts-2-3.png";
import concept1_right_bar from "./../../assets/images/abc/concepts/option1_right_bar.png";
import concept1_modal_success from "./../../assets/images/abc/concepts/option1_modal-success.png";
import concept1_alerts from "./../../assets/images/abc/concepts/option1_alerts.png";
import concept3 from "./../../assets/images/abc/concepts/ogconcepts-3.png";

class SmartRoster extends Component {
  componentDidMount() {
    new Glide('.glide-sr').mount();
  }

  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
      
    const sliderImgs = importAll(require.context(`./slider/smart-roster`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div className="content-smart-roster">
        <div className="container content-block">
          <div className="row">
            <PageHeader
              title={"Smart Roster"}
              techDescription={"The Smart Roster was maybe the second major overhaul in direction, team structure, etc. of \"DataTrak 3.0\". The Smart Roster was a tablet-only app that club staff would use at the entrance to check in members on the fly."}
            >
            </PageHeader>
          </div>
          <div className="row">
            <div className="glide glide-sr">
              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">

                  {sliderImgs.map(function(name, index) {
                    return <li className="glide__slide" key={index}><img src={name[1]} alt="Slider slide"/></li>;
                  })}

                </ul>
              </div>
              <div className="glide__bullets row" data-glide-el="controls[nav]">
                {sliderImgs.map(function(name, index) {
                  return <button className="glide__bullet col-3" key={index} data-glide-dir={`=${index}`}><img src={name[1]} alt="Slider navigation"/></button>;
                })}
              </div>
            </div>
          </div>

          <div className="comp-concepts-container">
            <div className="row shift-slide right">
              <div className="slide-blurb">
                The first of 3 style concepts I was tasked to comp prior to the product shift to what would become the Smart Roster app above.
              </div>
              <div className="col-12 slide-img">
                <img className="concept2_blue" src={concept2_1}/>
                <img className="concept2_green" src={concept2_2}/>
                <img className="concept2_pink" src={concept2_3}/>
              </div>
            </div>

            <div className="row shift-slide left">
              <div className="slide-blurb">
                The second of 3 concepts that stay a little more true to flat and material design.
              </div>
              
              <div className="col-12 row slide-img">
                <div className="col-4">
                  <img className="concept1_modal_success" src={concept1_modal_success}/>
                  <img className="concept1_alerts" src={concept1_alerts}/>
                </div>
                <div className="col-8">
                  <img className="concept1_right_bar" src={concept1_right_bar}/>
                </div>
              </div>
            </div>

            <div className="row shift-slide right">
              <div className="slide-blurb">
                The last of 3 concepts based on ABC Financial's existing brand/style guide.
              </div>
              <div className="col-12">
                <img className="concept3" src={concept3}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SmartRoster;
