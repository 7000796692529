import React, { Component } from "react";

import {PageHeader} from "./../../components/PageHeader";

import tropBranding from "./../../assets/images/cof-wedding/trop-branding.png";

class Branding extends Component {
  render() {
    return (
      <div className="content-branding">
        <div className="container content-block">
          <PageHeader
            title={"Branding"}
            techDescription={"I created the brand guidelines for the Tropicana Chapel because they did not have any brand in place yet."}
          ></PageHeader>
          
          <img src={tropBranding} />
        </div>
      </div>
    );
  }
}

export default Branding;
