import React, { Component } from "react";
import Glide from '@glidejs/glide'
import { HashLink as Link } from 'react-router-hash-link';

import Sidebar from "./../../components/Sidebar";
import Concept from "./../../components/Concept";
import {ChallengeBlock, Challenges} from "./../../components/Challenges";
import {PageHeader, SubHeading} from "./../../components/PageHeader";

import SmartRoster from "./SmartRoster";
import ComponentLibrary from "./ComponentLibrary";

class ABCFinancial extends Component {
  componentDidMount() {
    new Glide('.glide-bb').mount();
  }

  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
      
    const sliderImgs = importAll(require.context(`./slider/bigbox`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div>
        <div className="content-abc">
          <div className="container content-block">
            <div className="row">
              <PageHeader
                title={"Gym Management Software"}
                techDescription={["These comps were drawn primarily using Sketch and prototyped either within InVision or Axure. Comps were built of components stored in shared ",
                  <Link to="#component-library" scroll={el => el.scrollIntoView({ behavior: 'smooth'})}>component library</Link>,
                  "that we maintained for both the designers and developers."]}
              >
                <SubHeading 
                  skill={"Design + Development"}
                  link={"http://www.abcfinancial.com/"}
                  linkTitle={"abcfinancial.com"}
                />
              </PageHeader>
            </div>
            <div className="row">
              <Sidebar 
                className={"right"} 
                disclaimer={"The company brand site was not built by us in-house, but you can learn more about ABC Financial at the link above."}
                sectionTitle={"DataTrak 3.0"}
                description={"It's the 3rd iteration of their main product DataTrak 2.0. They never liked us calling it 3.0, but there is/was no other name being used."}
                >
              </Sidebar>
              
              <div className="glide glide-bb">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">

                    {sliderImgs.map(function(name, index) {
                      return <li className="glide__slide" key={index}><img src={name[1]} alt="Slider slide"/></li>;
                    })}

                  </ul>
                </div>
                <div className="glide__bullets row" data-glide-el="controls[nav]">
                  {sliderImgs.map(function(name, index) {
                    return <button className="glide__bullet col-2" key={index} data-glide-dir={`=${index}`}><img src={name[1]} alt="Slider navigation"/></button>;
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <Concept 
                txt={"This app is comprised of several subapps for every function of managing a club including member management, finances (even collections), a staff portal, call center management, a developer portal, etc. The needs of a \"big box\" club versus an independent \"boutique\" club required each feature to be modular to be ticked on or off based on the club type and staff role."}
              >
              </Concept>
            </div>

            <Challenges 
              sectionTitle={"Challenges"}>
              <ChallengeBlock 
                columnClass={"col-4"}
                txt={"Revolving door of not just engineers from the offshore machine but of the machines themselves."}/>
              <ChallengeBlock 
                columnClass={"col-4"}
                txt={"Frequent overhauls of project features and requirements, comps, and even team structures and management hierarchy."}/>
              <ChallengeBlock 
                columnClass={"col-4"}
                txt={"Unfortunately, nothing ever went live."}/>
            </Challenges>
          </div>
        </div>

        <SmartRoster/>
        <div id="influential-component-library">
          <ComponentLibrary/>
        </div>
        
      </div>
    );
  }
}

export default ABCFinancial;
