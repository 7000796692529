import React, { Component } from "react";
import Glide from '@glidejs/glide'

import Sidebar from "./../../components/Sidebar";
import Concept from "./../../components/Concept";
import {ChallengeBlock, Challenges} from "./../../components/Challenges";
import {PageHeader, SubHeading} from "./../../components/PageHeader";

import watsonLogo from "./../../assets/images/influential/watson-logo.png";
import mobileLoginScreenshot from "./../../assets/images/influential/app/mobile-login.png";
import mobileDashboardScreenshot from "./../../assets/images/influential/app/mobile-dashboard.png";
import mobileCampaignsScreenshot from "./../../assets/images/influential/app/mobile-campaigns.png";

import Campaigns from "./Campaigns";

class Influential extends Component {
  
  componentDidMount() {
    new Glide('.glide').mount();
  }

  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
    
    const sliderImgs = importAll(require.context(`./slider/app`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div>
        <div className="content-influential-app">
          <div className="container content-block">
            <PageHeader
              title={"Influential Network"}
              techDescription={"My role on this app was split between comping off of existing branding (logos, colors, fonts), and building out the UI components in ReactJS."}
            >
              <SubHeading 
                skill={"Design + Development"}
                link={"http://www.influential.co"}
                linkTitle={"influential.co"}
              />
            </PageHeader>
            
            <div className="glide">
              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">

                  {sliderImgs.map(function(name, index) {
                    return <li className="glide__slide" key={index}><img src={name[1]} alt="Slider slide"/></li>;
                  })}

                </ul>
              </div>
              <div className="glide__bullets row" data-glide-el="controls[nav]">
                {sliderImgs.map(function(name, index) {
                  return <button className="glide__bullet col-3" key={index} data-glide-dir={`=${index}`}><img src={name[1]} alt="Slider navigation"/></button>;
                })}
              </div>
              <Sidebar 
                className={"left"} 
                disclaimer={"The webapp is not accessible to non-members of The Influential Network. In addition to these screenshots, I salvaged an old InVision demo:"}
                sectionTitle={"Influencer Marketing platform"}
                description={"A webapp that connects social media influencers with brands that want to hire them to run promotions."}
              >
                <div className="link">
                  <p className="link-title">Wayback Machine</p>
                  <a href="https://rebrand.ly/hhl48o" className="link-url">https://rebrand.ly/hhl48o</a>
                </div>
                <div className="link">
                  <p className="link-title">Twitter Hashtag Feed</p>
                  <a href="https://rebrand.ly/hhl48o" className="link-url">https://rebrand.ly/haru5k</a>
                </div>
              </Sidebar>
            </div>
            
            <Concept 
              txt={["Influencers and brands each have separate login portals into the app. Brands have the ability to create scheduled campaigns hire influencers to carry them out in whatever media format they desire.", 
                <img src={watsonLogo} className="watson-logo" alt="IBM Watson logo"/>, 
                "The brands can make their own selection of influencers, or they can utilize the IBM Watson integration to return a list of influencers that match the brand’s values."]}
            >
            </Concept>

            <div className="split-container">
              <div className="row">
                <div className="col-12 col-md-7">
                  <img src={mobileLoginScreenshot} className="mobile-ss-login" alt="Influential app - mobile login"/>
                  <img src={mobileDashboardScreenshot} className="mobile-ss-dashboard" alt="Influential app - mobile dashboard"/>
                  <img src={mobileCampaignsScreenshot} className="mobile-ss-campaigns" alt="Influential app - mobile campaigns"/>
                </div>
                <div className="col-12 col-md-5">
                  <Challenges sectionTitle={"Responsive"} stack>
                  <ChallengeBlock 
                    txt={"I like to stick with Bootstrap’s grid system unless I have a good reason to not, such as maybe tweaking breakpoints."}
                  />
                  <ChallengeBlock 
                    hasDottedLine={true}
                    txt={"Media creation is obviously easier on a phone."}
                  />
                  </Challenges>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="influential-campaigns">
          <Campaigns/>
        </div>
        
      </div>
    );
  }
}

export default Influential;
