import React, { Component } from "react";
import Glide from '@glidejs/glide'

import Sidebar from "./../../components/Sidebar";
import Concept from "./../../components/Concept";
import {ChallengeBlock, Challenges} from "./../../components/Challenges";
import {PageHeader, SubHeading} from "./../../components/PageHeader";

import mobileScreenshot1 from "./../../assets/images/cof-wedding/trop-mobile-1.png";
import mobileScreenshot2 from "./../../assets/images/cof-wedding/trop-mobile-2.png";

import Branding from "./Branding";

class COFWeddings extends Component {
  componentDidMount() {
    new Glide('.glide').mount();
  }

  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
    
    const sliderImgs = importAll(require.context(`./slider`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div>
        <div className="content-cofweddings">
          <div className="container content-block">
            <PageHeader
              title={"EverAfter Wedding Booking"}
              techDescription={"I was tasked to build a wedding booking webapp with the ability to be reskinned for each of the affiliate chapels under my employer at the time, Ever After. I built a new frontend in AngularJS to replace an old .NET UI, and worked with an off-shore engineer to design a new API. The comps were created by a contractor before I started."}
            >
              <SubHeading 
                skill={"Development + Design"}
                link={"http://www.tropicanalvweddings.com"}
                linkTitle={"tropicanalvweddings.com"}
              />
              <SubHeading 
                skill={"Development"}
                link={"http://www.littlechapel.com"}
                linkTitle={"littlechapel.com"}
              />
            </PageHeader>
            
            <div className="glide">
              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">

                  {sliderImgs.map(function(name, index) {
                    return <li className="glide__slide" key={index}><img src={name[1]} alt="Slider slide"/></li>;
                  })}

                </ul>
              </div>
              <div className="glide__bullets row" data-glide-el="controls[nav]">
                {sliderImgs.map(function(name, index) {
                  return <button className="glide__bullet col-2" key={index} data-glide-dir={`=${index}`}><img src={name[1]} alt="Slider navigation"/></button>;
                })}
              </div>

              <Sidebar 
              className={"right"} 
              disclaimer={"I’ve not touched this site since 2015, so I’m not responsible for any style discrepencies accrued since then."}
              sectionTitle={"Wedding booking platform"}
              description={["A multi-tennant site where users can book a chapel, choose a package of amenities, and then save a date and time within our system.", 
                <span><br/><br/></span>, 
                "Couples can also create their own wedding page and have it hosted on the platform. Features include a guest list, a gifts section ,and a link to a live recording of the wedding to share with guests that can’t attend in-person."]}
            >
            </Sidebar>
            
            </div>
            
            <Concept 
              txt={["The site sends you down 2 different funnels depending on whether you start with a venue or a package. Regardless, both ways send you down the same following steps to select a date and time, select add-ons, and finally check out.", 
                <br/>, 
                "There are also many static (no SSR* necessary) white pages with package add-ons to purchase as well as chapel info, City of Las Vegas marriage laws, etc."]}
            >
            </Concept>
            
            <div className="split-container">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <img src={mobileScreenshot1} className="tablet-ss" alt="Tropicana Weddings - tablet"/>
                  <img src={mobileScreenshot2} className="mobile-ss" alt="Tropicana Weddings - mobile"/>
                </div>
                <div className="col-12 col-sm-6 align-self-center">
                  <Challenges sectionTitle={"Responsive"} stack>
                  <ChallengeBlock 
                    txt={["I like to stick with Bootstrap’s grid system unless I have a good reason to not, such as maybe tweaking breakpoints.", 
                    <span><br/><br/></span>, 
                    "Media creation is obviously easier on a phone."]}
                  />
                  </Challenges>
                </div>
              </div>
            </div>

            <Challenges sectionTitle={"Challenges"}>
              <ChallengeBlock 
                columnClass={"col-md-6"}
                txt={"As project lead, made all final decisions on technologies to be used, trained team of backend guys on the front-end stack (AngularJS, Webpack config, Git, etc.)"}/>
              <ChallengeBlock 
                hasDottedLine
                columnClass={"col-md-6"}
                txt={"Learned about and implemented server-side rendering of SPAs as an SEO necessity."}/>
            </Challenges>
          </div>
        </div>

        <Branding/>

      </div>

    );
  }
}

export default COFWeddings;
