import React, { Component } from "react";
import Glide from '@glidejs/glide'

import Sidebar from "./../../components/Sidebar";
import Concept from "./../../components/Concept";
import {PageHeader, SubHeading} from "./../../components/PageHeader";

import mxIcons from "./../../assets/images/allegiant/g4_icons.png";
import portalLight from "./../../assets/images/allegiant/portal_light.png";
import portalDark from "./../../assets/images/allegiant/portal_dark.png";

import AOS from "./AOS";

class Allegiant extends Component {
  componentDidMount() {
    new Glide('.glide').mount();
  }

  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
      
    const sliderImgs = importAll(require.context(`./slider/basic-crud`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div>
        <div className="content-allegiant">
          <div className="container content-block">
            <div className="row">
              <PageHeader
                title={"Allegiant Travel Company"}
                techDescription={"We worked with the most modern stack at the time based on AngularJS 1.x with such classics as Gulp/Grunt, Bower (package manager), and Jade (template engine). We wrote unit tests, dabbled in writing automated tests, as well as mocking services which in turn helped the backend guys when designing the API. We were even writing in a JS preprocessor called CoffeeScript that pretty closely followed ES6 features to-come such as arrow functions and string literals."}
              >
                <SubHeading 
                  skill={"Development"}
                  link={"https://www.allegiantair.com/"}
                  linkTitle={"allegiantair.com"}
                />
              </PageHeader>
            </div>
            <div className="row">
              <Sidebar 
                className={"right"} 
                disclaimer={"The company brand site was not built by us in-house, but you can learn more about Allegiant Air at the link above."}
                sectionTitle={"Aircraft MX Portal"}
                description={"I was on a team of software engineers in charge of digitalizing the many documents, processes, FAA guidelines, etc. that the aircraft maintenance engineers required."}
                >
              </Sidebar>
              
              <div className="glide">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">

                    {sliderImgs.map(function(name, index) {
                      return <li className="glide__slide" key={index}><img src={name[1]} alt="Slider slide"/></li>;
                    })}

                  </ul>
                </div>
                <div className="glide__bullets row" data-glide-el="controls[nav]">
                  {sliderImgs.map(function(name, index) {
                    return <button className="glide__bullet col-3" key={index} data-glide-dir={`=${index}`}><img src={name[1]} alt="Slider navigation"/></button>;
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <Concept 
                txt={"The aircraft maintenance department had no real central source for all the regulations and practices they rely on, so my team built a collection of CRUD apps to manage them with a dashboard view, role permissions, etc."}
              >
              </Concept>
            </div>
          </div>
        </div>

        <AOS/>
        
        <div className="icons-container">
          <div className="container content-block">
            <div className="row">
              <div className="slide-blurb">
                These icons were me just having a little fun prior to grooming the CRUD apps scope. Due to the very specific nature of the kind of data we were working with, it was hard to find an icon set that made sense. Ultimately, we went with the design team's comps, but 
              </div>
              <div className="col-7">
                <img src={mxIcons} className="mx-icons" alt="MX icons"/>
              </div>
              <div className="col-5">
                <img src={portalLight} className="portal light" alt="Light MX portal"/>
                <img src={portalDark} className="portal dark" alt="Dark MX portal"/>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Allegiant;
