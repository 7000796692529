import React, { Component } from "react";
import Glide from '@glidejs/glide'

import Sidebar from "./../../components/Sidebar";
import Concept from "./../../components/Concept";
import {ChallengeBlock, Challenges} from "./../../components/Challenges";
import {PageHeader, SubHeading} from "./../../components/PageHeader";

import CMSScreenshot from "./../../assets/images/yourlvtherapist/cms.png";
import ResourcesScreenshot from "./../../assets/images/yourlvtherapist/resources.png";

class KaraUrieLott extends Component {
  componentDidMount() {
    new Glide('.glide').mount();
  }

  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
      
    const sliderImgs = importAll(require.context(`./slider`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div>
        <div className="content-kara-urie-lott">
          <div className="container content-block">
            
            <PageHeader
              title={"Kara Urie-Lott, MS LMFT"}
              techDescription={["I was pretty stoked to find ", <a href="https://roots.io/sage/">Sage by Roots</a>, " which makes Wordpress development a little more tolerable with tools like Webpack. I built this site as a custom theme that the designer could use for a multi-tenant situation in future projects. I added some custom fields in the CMS as well. The site is hosted on ", <a href="https://kinsta.com/">Kinsta</a>, ", and comps provided by ", <a href="http://www.8020co.com/" target="_blank">Lauder Corea.</a>]}
            >
              <SubHeading 
                skill={"Development"}
                linkTitle={"yourlvtherapist.com"}
                link={"http://yourlvtherapist.com"}
              />
            </PageHeader>

            <div className="glide">
              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">

                  {sliderImgs.map(function(name, index) {
                    return <li className="glide__slide" key={index}><img src={name[1]} alt="Slider slide"/></li>;
                  })}

                </ul>
              </div>
              <div className="glide__bullets row" data-glide-el="controls[nav]">
                {sliderImgs.map(function(name, index) {
                  return <button className="glide__bullet col-2" key={index} data-glide-dir={`=${index}`}><img src={name[1]} alt="Slider navigation"/></button>;
                })}
              </div>
              
              <Sidebar 
                className={"left"} 
                description={["Kara is a licensed marriage and family therapist based in my hometown of Las Vegas, Nevada.",
                <strong><br/><br/>Fun Fact: </strong>,
                "Her brother is apparently the lead singer of Panic at the Disco."]}
                >
              </Sidebar>
            </div>

            <Concept 
              txt={"This is a standard brand site that allows the user to research Kara's credentials and specialties, complete paperwork prior to their scheduled session, and pay for said session via Square or PayPal."}
            >
            </Concept>

            <div className="split-container">
              <div className="row">
                <div className="col-7">
                  <img src={CMSScreenshot} className="tablet-ss-cms" alt="yourlvtherapist.com custom Wordpress theme"/>
                  <img src={ResourcesScreenshot} className="mobile-ss-resources" alt="yourlvtherapist.com custom Wordpress theme"/>
                </div>
                <div className="col-5 align-self-center">
                
                <Challenges 
                  sectionTitle={"User-friendly"}
                  iconClass={"fab fa-wordpress-simple"}
                >
                  <ChallengeBlock 
                    txt={"Custom fields for the client's needs, especially because they'd never used Wordpress before."}/>
                </Challenges>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KaraUrieLott;
