import React, { Component } from 'react';

class PageHeader extends Component {
  render() {
    return (
      <div className="page-header-container">
        <h2>{this.props.title}</h2>
        {this.props.children}
        <hr/>
        
        {this.props.techDescription ? 
          <p className="tech-used">{this.props.techDescription}</p>
        : null}
      </div>
    );
  } 
}

class SubHeading extends Component {
  render() {
    return (
      <div className="subtitle">
        <span className="subtitle-skill">{this.props.skill}</span>
        <span className="subtitle-link asterisk"><a href={`${this.props.link}`} target="_blank">{this.props.linkTitle}</a></span>
      </div>
    );
  } 
}

export {PageHeader, SubHeading};
