import React, { Component } from 'react';

class Challenges extends Component {
  render() {
    return (
      <div className={`challenges-container ${this.props.stack ? 'stack' : 'long'}`}>
        {this.props.iconClass ? 
          <i className={this.props.iconClass}></i>
        : null}
        <p className="section-title">{this.props.sectionTitle}</p>
        <div className="row">
          {this.props.children}
        </div>
      </div>
    );
  }
}

class ChallengeBlock extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      hasDottedLine: props.hasDottedLine
    }
  }

  render() {
    return (
      <div className={`challenge-box ${this.props.columnClass ? this.props.columnClass : 'col-12'}`}>
        {this.state.hasDottedLine ? 
          <hr className="dotted-line"/>
        : null}
        <div>{this.props.txt}</div>
      </div>
    );
  }
}

export {ChallengeBlock, Challenges};
