import React, { Component } from 'react';

class Sidebar extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      disclaimer: props.disclaimer,
      sectionTitle: props.sectionTitle,
      description: props.description
    }
  }

  render() {
    return (
      <div className={`sidebar-container ${this.props.className}`}>

        {this.state.disclaimer ? 
          <div>
            <p className="disclaimer asterisk">{this.state.disclaimer}</p>
            <hr className="dotted-line"/> 
          </div>
        : null}

        {this.state.sectionTitle ? 
          <div>
            <p className="section-title">{this.state.sectionTitle}</p>
          </div>
        : null}

        {this.state.description ? 
          <div>
            <p className="description">{this.state.description}</p>
          </div>
        : null}
        
        {this.props.children}
        
      </div>
    );
  }
}

export default Sidebar;
