import React, { Component } from "react";

import {PageHeader, SubHeading} from "./../../components/PageHeader";

class ComponentLibrary extends Component {
  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
      
    const sliderImgs = importAll(require.context(`./slider/component-library`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div className="content-component-library">
        <div className="container content-block">
          <div className="row">
            <PageHeader
              title={"Atomic Component Library"}
              techDescription={"A coworker and I assembled in Sketch a shared library (utilizing Abstract for version control and managing assets) of all the components we were designing in our comps. We hosted these Sketch files within InVision for the developers to gather specs via InVision's Inspect tool, who in turn built the components using ReactJS Storybook. We organized our component library using the Atomic methodology."}
            >
            </PageHeader>
          </div>
          <div className="row">
            {sliderImgs.map(function(name, index) {
              return <div className="col-4"><img src={name[1]} key={index} alt="Slider slide"/></div>;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ComponentLibrary;
