import React, { Component } from "react";
import Glide from '@glidejs/glide'

import Sidebar from "./../../components/Sidebar";
import Concept from "./../../components/Concept";
import {PageHeader, SubHeading} from "./../../components/PageHeader";

import mobileScreenshot1 from "./../../assets/images/hrs/mobile-1.png";
import mobileScreenshot2 from "./../../assets/images/hrs/mobile-2.png";
import tabletScreenshot from "./../../assets/images/hrs/tablet-1.png";

class HRS extends Component {
  componentDidMount() {
    new Glide('.glide').mount();
  }

  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
      
    const sliderImgs = importAll(require.context(`./slider`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div>
        <div className="content-hrs">
          <div className="container content-block">
            <div className="row">
              <PageHeader
                title={"Hard Right Solutions"}
                techDescription={["I built this site using ", <a href="https://nextjs.org/">NextJS</a>," as it was only meant to be a static site, and there was no desire by the client for a CMS. Comps provided by ", 
                <a href="http://www.wirekite.co/" target="_blank">Wirekite.</a>]}
              >
                <SubHeading 
                  skill={"Development"}
                  linkTitle={"hardrightsolutions.com/"}
                  link={"https://pedantic-franklin-a36c00.netlify.com/"}
                />
              </PageHeader>
            </div>
            <div className="row">
              <Sidebar 
                className={"right"} 
                disclaimer={"This site is in staging mode still, thus the netlify link above, unpaid stock images, etc.."}
                description={"HRS (Hard Right Solutions) builds custom software for each of their clients' individual financial and reporting needs."}
                >
              </Sidebar>
              
              <div className="glide">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">

                    {sliderImgs.map(function(name, index) {
                      return <li className="glide__slide" key={index}><img src={name[1]} alt="Slider slide"/></li>;
                    })}

                  </ul>
                </div>
                <div className="glide__bullets row" data-glide-el="controls[nav]">
                  {sliderImgs.map(function(name, index) {
                    return <button className="glide__bullet col-2" key={index} data-glide-dir={`=${index}`}><img src={name[1]} alt="Slider navigation"/></button>;
                  })}
                </div>
              </div>
            </div>

            <div className="row">
              <Concept txt={"This is a standard brand site that allows the user to meet the team and research their services."}/>
            </div>

            <div className="split-container">
              <div className="row">
                <div className="col-12">
                  <img src={tabletScreenshot} className="tablet-why-hrs" alt="HRS - tablet"/>
                  <img src={mobileScreenshot1} className="mobile-case-study" alt="HRS - mobile"/>
                  <img src={mobileScreenshot2} className="mobile-meet-team" alt="HRS - mobile"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HRS;
