import React, { Component } from "react";
import Glide from '@glidejs/glide'

import Sidebar from "./../../components/Sidebar";
import Concept from "./../../components/Concept";
import {ChallengeBlock, Challenges} from "./../../components/Challenges";
import {PageHeader, SubHeading} from "./../../components/PageHeader";

class SobelWestex extends Component {
  componentDidMount() {
    new Glide('.glide').mount();
  }

  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
      
    const sliderImgs = importAll(require.context(`./slider`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div>
        <div className="content-sobel-westex">
          <div className="container content-block">
            <div className="row">
              <PageHeader
                title={"Sobel Westex"}
                techDescription={"This was a rebuild of a site for the AS/400 platform that relplaced the \"Storefront\" boilerplate that it shipped with. The new site I built was entirely PHP, no framework. Yes, it was a stupid and harrowing decision."}
              >
                <SubHeading 
                  skill={"Development"}
                  link={"http://www.sobelathome.com"}
                  linkTitle={"sobelathome.com"}
                />
              </PageHeader>
            </div>
            <div className="row">
              <Sidebar 
                className={"left"} 
                disclaimer={"This version of the site is long gone from sobelathome.com, but here are some links that paint a picture of this project."}
                sectionTitle={"sobelathome.com v.2"}
                description={"An ecommerce site to extend the sale of their towels and bedding products beyond B2B to B2C."}
                >
                  <div className="link">
                    <p className="link-title">Wayback Machine</p>
                    <a href="https://rebrand.ly/27vfk3" className="link-url">https://rebrand.ly/27vfk3</a>
                  </div> 
                </Sidebar>
              
              <div className="glide">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">

                    {sliderImgs.map(function(name, index) {
                      return <li className="glide__slide" key={index}><img src={name[1]} alt="Slider slide"/></li>;
                    })}

                  </ul>
                </div>
                <div className="glide__bullets row" data-glide-el="controls[nav]">
                  {sliderImgs.map(function(name, index) {
                    return <button className="glide__bullet col-2" key={index} data-glide-dir={`=${index}`}><img src={name[1]} alt="Slider navigation"/></button>;
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <Concept 
                txt={"I was ashamed of this project for a long time, but now I can look back on it lovingly as a testiment to my stick-to-itiveness. I came onboard as a frontend developer, and one thing lead to another to where I was building this entire site with spaghetti PHP, no framework, and traversing the iSeries documentation alongside Googling basic PHP patterns. This code deserves to be deeper in the ground than the Ataris E.T. cartridge grave. Somehow, however, the site was live and working by the time I left the company."}
              >
              </Concept>
            </div>

            <Challenges 
              sectionTitle={"Challenges"}>
              <ChallengeBlock 
                columnClass={"col-12"}
                txt={"Overall challenge was just getting something functional out there with my limited skills at the time."}/>
            </Challenges>
          </div>
        </div>
      </div>
    );
  }
}

export default SobelWestex;
