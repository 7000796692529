import React, { Component } from "react";
import Glide from '@glidejs/glide'

import {PageHeader, SubHeading} from "./../../components/PageHeader";

import gallowsDesktop from "./../../assets/images/influential/campaigns/gallows_desktop.png";
import gallowsMobile1 from "./../../assets/images/influential/campaigns/gallows_mobile_1.png";
import gallowsMobile2 from "./../../assets/images/influential/campaigns/gallows_mobile_2.png";

import jottDesktop from "./../../assets/images/influential/campaigns/jott_desktop.png";
import jottMobile from "./../../assets/images/influential/campaigns/jott_mobile.png";

import unMobile from "./../../assets/images/influential/campaigns/un_mobile.png";
import unDesktop from "./../../assets/images/influential/campaigns/un_desktop.png";

import huffPost from "./../../assets/images/influential/campaigns/huffpost.png";

class Campaigns extends Component {
  
  componentDidMount() {
    new Glide('.glide-camp', {
      type: 'carousel',
      perView: 3,
      focusAt: 'center',
      peek: {
        before: 50,
        after: 50
      },
      autoplay: 2000
    }).mount();
  }

  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
    
    const sliderImgs = importAll(require.context(`./slider/campaigns`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div className="content-influential-campaigns">
        <div className="container content-block">

          <PageHeader
            title={"Influential Campaigns"}
            techDescription={"All of these campaigns were built with vanilla JS, HTML, CSS + Twitter and Google Analytics includes. I did whatever little graphics work was required on each one."}
          >
            <SubHeading 
              skill={"Design + Development"}
              link={"http://www.influential.co"}
              linkTitle={"influential.co"}
            />
          </PageHeader>

          <div className="un-container">
            <div className="row no-gutters">
              <div className="col-12">
                {/* <img src={unDesktop} className="un-desktop" alt="United Nations Peace Day campaign - desktop"/>
                <img src={unMobile} className="un-mobile" alt="United Nations Peace Day campaign - mobile"/> */}
                <img src={huffPost} className="un-huffpost" alt="Huffington Post - Forgive for Peace campaign"/>
              </div>
            </div>
          </div>

          <hr/>

          <div className="glide glide-camp">
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides">

                {sliderImgs.map(function(name, index) {
                  return <li className="glide__slide" key={index}><img src={name[1]} alt="Slider slide"/></li>;
                })}
              </ul>
            </div>
          </div>
          
          <div className="gallows-container">
            <PageHeader
              title={"The Gallows"}
            >
              <SubHeading 
                skill={"Design + Development"}
                link={"https://www.imdb.com/title/tt2309260/"}
                linkTitle={"imdb.com"}
              />
            </PageHeader>
              
            <img src={gallowsMobile1} className="gallows-mobile-1" alt="The Galllows movie - mobile"/>
            <img src={gallowsMobile2} className="gallows-mobile-2" alt="The Galllows movie - mobile"/>
            <img src={gallowsDesktop} className="gallows-desktop" alt="The Galllows movie - desktop"/>
            <div className="clearfix"></div>
          </div>

          <div className="jott-container">
            <PageHeader
              title={"Jott Messaging App"}
            >
              <SubHeading 
                skill={"Design + Development"}
                link={"https://techcrunch.com/2015/06/08/messaging-app-jott-is-blowing-up-among-junior-high-and-high-schoolers/"}
                linkTitle={"techcrunch.com"}
              />
            </PageHeader>
            
            <div className="constraint-box">
              <img src={jottDesktop} className="jott-desktop" alt="Jott app - desktop"/>
              <img src={jottMobile} className="jott-mobile" alt="Jott app - mobile"/>
            <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Campaigns;

