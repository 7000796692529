import React, { Component } from "react";
import Glide from '@glidejs/glide';

import Sidebar from "./../../components/Sidebar";
import Concept from "./../../components/Concept";
import {ChallengeBlock, Challenges} from "./../../components/Challenges";
import {PageHeader, SubHeading} from "./../../components/PageHeader";

import mobileScreenshot from "./../../assets/images/ranch-laguna/ranchlb5.png";

class RanchLaguna extends Component {
  componentDidMount() {
    new Glide('.glide').mount();
  }

  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
    
    const sliderImgs = importAll(require.context(`./slider`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div>
        <div className="content-laguna">
          <div className="container content-block">
            <PageHeader
              title={"The Ranch at Laguna Beach"}
            >
              <SubHeading 
                skill={"Design + Development"}
                link={"http://www.theranchlb.com"}
                linkTitle={"theranchlb.com"}
              />
            </PageHeader>

            <div className="glide">
              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">

                  {sliderImgs.map(function(name, index) {
                    return <li className="glide__slide" key={index}><img src={name[1]} alt="Slider slide"/></li>;
                  })}

                </ul>
              </div>
              <div className="glide__bullets row" data-glide-el="controls[nav]">
                {sliderImgs.map(function(name, index) {
                  return <button className="glide__bullet col-3" key={index} data-glide-dir={`=${index}`}><img src={name[1]} alt="Slider navigation"/></button>;
                })}
              </div>
              <Sidebar 
                className={"right"} 
                disclaimer={"The webapp is no longer live on theranchlb.com site, but here are some links that paint a picture of this project."}
                sectionTitle={"2015 Grand Opening promotion"}
                description={"Create a digital postcard with your own message of why you love Laguna Beach, then post it to your Facebook or Twitter to be entered in a golf package giveaway."}
              />
            </div>
            
            <Concept txt={"Once the user selects a postcard image they are prompted to enter a message. The image and the text are then drawn to an HTML5 Canvas element, exported to base64, saved to the server and then posted via Facebook or Twitter."}/>
            
            <div className="split-container">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <img src={mobileScreenshot} className="mobile-ss" alt="#RanchLB #OpenEarly2015"/>
                </div>
                <div className="col-12 col-sm-6 align-self-center">
                  <Challenges sectionTitle={"Challenges"} stack>
                    <ChallengeBlock 
                      txt={"Making the canvas logic compatible back to IE8."}/>
                    <ChallengeBlock 
                      hasDottedLine={true}
                      txt={"Finding a polyfill with the toDatUrl function, which is what allows you to export your canvas to a flattened image."}/>
                    <ChallengeBlock 
                      hasDottedLine={true}
                      txt={"Make a base64 image work with the old Facebook API."}/>
                  </Challenges>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RanchLaguna;
