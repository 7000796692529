import React, { Component } from "react";

import Concept from "./../../components/Concept";
import {ChallengeBlock, Challenges} from "./../../components/Challenges";
import {PageHeader, SubHeading} from "./../../components/PageHeader";

import ReactPlayer from 'react-player';

class InVision extends Component {
  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }

    return (
      <div>
        <div className="content-invision">
          <div className="container content-block">
            <PageHeader
              title={"InVision Enterprise"}
              techDescription={"A majority of the original prototyping tool was built using AngularJS 1.x on the frontend and ColdFusion on the backend. We built anew and refactored old stuff to ReactJS and NodeJS."}
            >
              <SubHeading 
                skill={"Development"}
                link={"http://www.invisionapp.com/inside-design/design-project-management-tool/"}
                linkTitle={"invisionapp.com"}
              />
            </PageHeader>
            
            <div className="player-wrapper">
              <ReactPlayer
                url="https://fast.wistia.com/medias/gsu8t80qem"
                className="react-player"
                playing
                width="100%"
                height="100%"
                controls={false}
              />
            </div>
            
            <Concept 
              txt={["Enterprise users get tools that expand upon the original prototyping tool that made InVision famous. These tools include a Kanban-style board to share and critique screen designs as well as some great commenting tools, large team management, and ", 
              <a href="https://www.invisionapp.com/inside-design/design-project-management-tool/">more...</a>]}
            >
            </Concept>
            
            <Challenges 
              sectionTitle={"Challenges"}>
              <ChallengeBlock 
                columnClass={"col-12"}
                txt={"ColdFusion was a weird language to go back to."}/>
            </Challenges>
          </div>
        </div>
      </div>
    );
  }
}

export default InVision;
