import React, { Component } from 'react';

class Footer extends Component {
  
  render() {
    return (
      <footer>
        <h1>Caitlin Smith</h1>
        <p class="job-title">UI/UX + Frontend Engineer</p>
        <hr/>
        <div className="social-icons">
          <i className="fab fa-linkedin"></i>
          <i className="fab fa-twitter"></i>
          <i className="fab fa-github"></i>
        </div>
      </footer>
    );
  }
}

export default Footer;
