import React, { Component } from "react";
import Glide from '@glidejs/glide'

import Concept from "./../../components/Concept";

import {PageHeader, SubHeading} from "./../../components/PageHeader";

class AOS extends Component {
  componentDidMount() {
    new Glide('.glide-aos').mount();
  }

  render() {
    function importAll(r) {
      let images = [];
      r.keys().map((item) => {
        images.push(
          [item.replace("./", ""), r(item)]
        )
        return null;
      });
      return images;
    }
    
    const sliderImgs = importAll(require.context(`./slider/aos`, false, /\.(png|jpe?g|svg)$/));

    return (
      <div className="content-aos">
        <div className="container content-block">
          <div className="row">
            <PageHeader
              title={"Aircraft Out of Service (AOS)"}
              techDescription={""}
            >
            </PageHeader>
          </div>
          <div className="row">
            <div className="glide glide-aos">
              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">

                  {sliderImgs.map(function(name, index) {
                    return <li className="glide__slide" key={index}><img src={name[1]} alt="Slider slide"/></li>;
                  })}

                </ul>
              </div>
              <div className="glide__bullets row" data-glide-el="controls[nav]">
                {sliderImgs.map(function(name, index) {
                  return <button className="glide__bullet col-2" key={index} data-glide-dir={`=${index}`}><img src={name[1]} alt="Slider navigation"/></button>;
                })}
              </div>
            </div>
          </div>
          <div className="row">
            <Concept 
              txt={"I also took lead over one of the higher-priority applications that tracks the fleet's airworthiness, where a flight is relative to their scheduled maintenance, how much time the flight has before it becomes critical, and other features you can see from the comps above."}
            >
            </Concept>
          </div>
        </div>
      </div>
    );
  }
}

export default AOS;
